export module common {
  export function convGetParams(): any {
    let params: any = {};
    let paramsPairs: string[] = [];
    let paramsPair: string[] = [];
    let paramKey: string = '';
    paramsPairs = decodeURI(location.search).replace(/^\?/, '').split('&');
    for (let i: number = 0; paramsPairs[i]; ++i) {
      paramsPair = paramsPairs[i].split('=');
      if (/.*\[\]$/.test(paramsPair[0])) {
        paramKey = paramsPair[0].replace('[]', '');
        if (!params[paramKey]) params[paramKey] = new Array();
        params[paramKey].push(paramsPair[1]);
      } else {
        params[paramsPair[0]] = paramsPair[1];
      }
    }
    return params;
  }

  // コンテンツの高さ取得
  export function getContentHeight(): number {
    let height: number = document.body.clientHeight;
    return height;
  }

  // フレームにpostMessage 送信処理
  export function sendMessage(target: string, values: any, origin: string, iframeId: string = null): void {
    let frame: Window;

    switch(target) {
      case 'parent':
        frame = parent.postMessage ? parent : undefined;
        break;
      default:
        frame = (<HTMLIFrameElement>document.getElementById(iframeId)).contentWindow;
        frame = frame.postMessage ? frame : undefined;
    }
    if (typeof frame != undefined) {
      frame.postMessage(JSON.stringify(values), origin);
    }
  }

  export function arrayOR(targets: string[], needles: string[]): any {
    let exists: boolean = false;
    let match: string = '';
    for (let i: number = 0; needles.length > i; ++i) {
      if (targets.indexOf(needles[i].trim()) > -1) {
        exists = true;
        match = needles[i].trim();
        break;
      }
    }
    return { result: exists, needle: match};
  }

  export function formatDate(date: string): string {
    let fmtDate: string = '';
    let eventDate: Date;
    let weekDay: string[] = ['日', '月', '火', '水', '木', '金', '土'];
    try {
      eventDate = new Date(date);
      fmtDate = eventDate.getFullYear() + '年' + (eventDate.getMonth()+1) + '月' + eventDate.getDate() + '日（' + weekDay[eventDate.getDay()] + '）';
    } catch(e) {
      fmtDate = date;
    }
    return fmtDate;
  }
}