import { searchShop } from './search_shop/search_shop';

interface Window {
  searchShop(elemId: string, master: any, jsonPath: string, perPage: number): void;
};
declare var window: Window;

// Customs
window.searchShop = (elemId, master, jsonPath, perPage) => {
  new searchShop(elemId, master, jsonPath, perPage);
};
