import Vue from 'vue/dist/vue.esm.js';
import axios from 'axios';
import { ADDRGETNETWORKPARAMS } from 'dns';
import { common } from '../lib/common';
import "core-js/stable";
import "regenerator-runtime/runtime";

export class searchShop {
  private vue_obj: Vue;

  constructor(elemId: string, master: any, jsonPath: string, perPage: number) {
    this.vue_obj = new Vue({
      el: '#' + elemId,
      data: {
        jsonPath: jsonPath,
        master: master,
        allRows: [],
        refinedRows: [],
        showRows: [],
        currentPage: 0,
        paramsKeys: ['shopping_street', 'industry_classification', 'coupons_available', 'keyword'],
        currentParams: {},
        showViewMore: false,
        resultExist: false,
        showMessage: '',
      },
      created: function () {
        this.clearParams();
        this.setSearchParams();
      },
      mounted: function () {
        axios
          .get(this.jsonPath)
          .then((response) => {
            if (response.data) {
              this.currentPage = 0;
              this.allRows = response.data;
              console.log(this.allRows);
              this.execSearch(this.allRows);
              this.addRows();
            }
          });
      },
      methods: {
        clearParams: function (): void {
          for (let key of this.paramsKeys) {
            this.currentParams[key] = null;
          }
        },
        setSearchParams: function (): void {
          let searchParams: any = common.convGetParams();
          for (let key of this.paramsKeys) {
            if (searchParams[key]) {
              this.currentParams[key] = searchParams[key];
            }
          }
        },
        execSearch: function (allRows: any): void {
          let row: any = {};
          let matches: any = {};
          let inputKeywords: string[] = [];
          let i: number = 0;
          const keyword = this.convKeyword(this.currentParams.keyword);

          for (i = 0; allRows.length > i; ++i) {
            matches = { 'shopping_street': true, 'industry_classification': true, 'coupons_available': true, 'keyword': true };
            row = allRows[i];
            if (this.currentParams.shopping_street != null && this.currentParams.shopping_street.toString(10).length > 0) { // 商店街
              matches.shopping_street = row.shopping_street.indexOf(this.currentParams.shopping_street.toString(10)) > -1;
            }
            if (this.currentParams.industry_classification != null && this.currentParams.industry_classification.toString(10).length > 0) { // 業種
              matches.industry_classification = row.industry_classification.indexOf(this.currentParams.industry_classification.toString(10)) > -1;
            }
            if (this.currentParams.coupons_available != null && this.currentParams.coupons_available) { // クーポン
              matches.coupons_available = row.coupons_available;
            }
            if (keyword != null) { // キーワード
              inputKeywords = keyword.split(' ');
              for (let i = 0; inputKeywords.length > i; ++i) if (row.search_keywords.indexOf(inputKeywords[i]) == -1) {
                matches.keyword = false;
                break;
              }
            }
            if (matches.shopping_street && matches.industry_classification && matches.coupons_available && matches.keyword) this.refinedRows.push(row)
          }
          if (this.refinedRows.length > 0) {
            this.resultExist = true;
            this.showMessage = '';
          } else {
            this.resultExist = false;
            this.showMessage = '該当する店舗がみつかりません。';
          }
        },
        addRows: function (): void {
          let row: any = {};
          for (let i: number = this.currentPage * perPage; ((this.currentPage + 1) * perPage) > i && this.refinedRows[i]; ++i) {
            row = this.refinedRows[i];
            this.showRows.push(row);
            // this.$set(this.showRows, 0, row);
          }
          this.showViewMore = (this.refinedRows.length > this.showRows.length);
          ++this.currentPage;
        },
        search: function (): void {
          this.refinedRows = [];
          this.showRows = [];
          this.currentPage = 0;
          this.execSearch(this.allRows);
          this.addRows();
        },
        resetForm: function (): void {
          this.clearParams();
          this.search();
        },
        convMasterLabel: function (type: string, ids: number[]): string {
          let label: string = '';
          if (ids && ids.length > 0) for (const item of this.master[type]) {
            if (item.id == ids[0]) {
              label = item.label;
              break;
            }
          }
          return label;
        },
        convKeyword: function (keywordStr: string): string {
          if (keywordStr == null || keywordStr.length == 0) return keywordStr;

          let keyword: string = ''
          const kanaMap = {
            'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
            'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
            'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
            'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
            'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
            'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
            'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
            'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
            'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
            'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
            'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
            'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
            'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
            'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
            'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
            'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
            'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
            'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
            '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
          };

          // conv HARF num&alphabet to FULL
          keyword = keywordStr.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
          });

          // conv to lower case
          keyword = keyword.toLowerCase();

          // conv HARF Katanaka to FULL
          const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
          keyword = keyword
            .replace(reg, function (match) { return kanaMap[match]; })
            .replace(/ﾞ/g, '゛')
            .replace(/ﾟ/g, '゜');

          // conv Katakana to Hiragana
          keyword = keyword.replace(/[\u30a1-\u30f6]/g, function (match) {
            const chr = match.charCodeAt(0) - 0x60;
            return String.fromCharCode(chr);
          });

          return keyword;
        },
      },
      computed: {
      },
    })
  }
}